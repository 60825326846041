import diamonds2AudioA from './ogg/c1-01-a.ogg'
import diamonds2AudioB from './ogg/c1-01-b.ogg'
import diamonds2AudioC from './ogg/c1-01-c.ogg'
import diamonds3AudioA from './ogg/c1-02-a.ogg'
import diamonds3AudioB from './ogg/c1-02-b.ogg'
import diamonds3AudioC from './ogg/c1-02-c.ogg'
import diamonds4AudioA from './ogg/c1-03-a.ogg'
import diamonds4AudioB from './ogg/c1-03-b.ogg'
import diamonds4AudioC from './ogg/c1-03-c.ogg'
import diamonds5AudioA from './ogg/c1-04-a.ogg'
import diamonds5AudioB from './ogg/c1-04-b.ogg'
import diamonds5AudioC from './ogg/c1-04-c.ogg'
import diamonds6AudioA from './ogg/c1-05-a.ogg'
import diamonds6AudioB from './ogg/c1-05-b.ogg'
import diamonds6AudioC from './ogg/c1-05-c.ogg'
import diamonds7AudioA from './ogg/c1-06-a.ogg'
import diamonds7AudioB from './ogg/c1-06-b.ogg'
import diamonds7AudioC from './ogg/c1-06-c.ogg'
import diamonds8AudioA from './ogg/c1-07-a.ogg'
import diamonds8AudioB from './ogg/c1-07-b.ogg'
import diamonds8AudioC from './ogg/c1-07-c.ogg'
import diamonds9AudioA from './ogg/c1-08-a.ogg'
import diamonds9AudioB from './ogg/c1-08-b.ogg'
import diamonds9AudioC from './ogg/c1-08-c.ogg'
import diamonds10AudioA from './ogg/c1-09-a.ogg'
import diamonds10AudioB from './ogg/c1-09-b.ogg'
import diamonds10AudioC from './ogg/c1-09-c.ogg'
import diamondsJAudioA from './ogg/c1-10-a.ogg'
import diamondsJAudioB from './ogg/c1-10-b.ogg'
import diamondsJAudioC from './ogg/c1-10-c.ogg'
import diamondsQAudioA from './ogg/c1-11-a.ogg'
import diamondsQAudioB from './ogg/c1-11-b.ogg'
import diamondsQAudioC from './ogg/c1-11-c.ogg'
import diamondsKAudioA from './ogg/c1-12-a.ogg'
import diamondsKAudioB from './ogg/c1-12-b.ogg'
import diamondsKAudioC from './ogg/c1-12-c.ogg'
import diamondsAAudioA from './ogg/c1-13-a.ogg'
import diamondsAAudioB from './ogg/c1-13-b.ogg'
import diamondsAAudioC from './ogg/c1-13-c.ogg'
import spades2AudioA from './ogg/c1-14-a.ogg'
import spades2AudioB from './ogg/c1-14-b.ogg'
import spades2AudioC from './ogg/c1-14-c.ogg'
import spades3AudioA from './ogg/c1-15-a.ogg'
import spades3AudioB from './ogg/c1-15-b.ogg'
import spades3AudioC from './ogg/c1-15-c.ogg'
import spades4AudioA from './ogg/c1-16-a.ogg'
import spades4AudioB from './ogg/c1-16-b.ogg'
import spades4AudioC from './ogg/c1-16-c.ogg'
import spades5AudioA from './ogg/c1-17-a.ogg'
import spades5AudioB from './ogg/c1-17-b.ogg'
import spades5AudioC from './ogg/c1-17-c.ogg'
import spades6AudioA from './ogg/c1-18-a.ogg'
import spades6AudioB from './ogg/c1-18-b.ogg'
import spades6AudioC from './ogg/c1-18-c.ogg'
import spades7AudioA from './ogg/c1-19-a.ogg'
import spades7AudioB from './ogg/c1-19-b.ogg'
import spades7AudioC from './ogg/c1-19-c.ogg'
import spades8AudioA from './ogg/c1-20-a.ogg'
import spades8AudioB from './ogg/c1-20-b.ogg'
import spades8AudioC from './ogg/c1-20-c.ogg'
import spades9AudioA from './ogg/c1-21-a.ogg'
import spades9AudioB from './ogg/c1-21-b.ogg'
import spades9AudioC from './ogg/c1-21-c.ogg'
import spades10AudioA from './ogg/c1-22-a.ogg'
import spades10AudioB from './ogg/c1-22-b.ogg'
import spades10AudioC from './ogg/c1-22-c.ogg'
import spadesJAudioA from './ogg/c1-23-a.ogg'
import spadesJAudioB from './ogg/c1-23-b.ogg'
import spadesJAudioC from './ogg/c1-23-c.ogg'
import spadesQAudioA from './ogg/c1-24-a.ogg'
import spadesQAudioB from './ogg/c1-24-b.ogg'
import spadesQAudioC from './ogg/c1-24-c.ogg'
import spadesKAudioA from './ogg/c1-25-a.ogg'
import spadesKAudioB from './ogg/c1-25-b.ogg'
import spadesKAudioC from './ogg/c1-25-c.ogg'
import spadesAAudioA from './ogg/c1-26-a.ogg'
import spadesAAudioB from './ogg/c1-26-b.ogg'
import spadesAAudioC from './ogg/c1-26-c.ogg'
import hearts2AudioA from './ogg/c1-27-a.ogg'
import hearts2AudioB from './ogg/c1-27-b.ogg'
import hearts2AudioC from './ogg/c1-27-c.ogg'
import hearts3AudioA from './ogg/c1-28-a.ogg'
import hearts3AudioB from './ogg/c1-28-b.ogg'
import hearts3AudioC from './ogg/c1-28-c.ogg'
import hearts4AudioA from './ogg/c1-29-a.ogg'
import hearts4AudioB from './ogg/c1-29-b.ogg'
import hearts4AudioC from './ogg/c1-29-c.ogg'
import hearts5AudioA from './ogg/c1-30-a.ogg'
import hearts5AudioB from './ogg/c1-30-b.ogg'
import hearts5AudioC from './ogg/c1-30-c.ogg'
import hearts6AudioA from './ogg/c1-31-a.ogg'
import hearts6AudioB from './ogg/c1-31-b.ogg'
import hearts6AudioC from './ogg/c1-31-c.ogg'
import hearts7AudioA from './ogg/c1-32-a.ogg'
import hearts7AudioB from './ogg/c1-32-b.ogg'
import hearts7AudioC from './ogg/c1-32-c.ogg'
import hearts8AudioA from './ogg/c1-33-a.ogg'
import hearts8AudioB from './ogg/c1-33-b.ogg'
import hearts8AudioC from './ogg/c1-33-c.ogg'
import hearts9AudioA from './ogg/c1-34-a.ogg'
import hearts9AudioB from './ogg/c1-34-b.ogg'
import hearts9AudioC from './ogg/c1-34-c.ogg'
import hearts10AudioA from './ogg/c1-35-a.ogg'
import hearts10AudioB from './ogg/c1-35-b.ogg'
import hearts10AudioC from './ogg/c1-35-c.ogg'
import heartsJAudioA from './ogg/c1-36-a.ogg'
import heartsJAudioB from './ogg/c1-36-b.ogg'
import heartsJAudioC from './ogg/c1-36-c.ogg'
import heartsQAudioA from './ogg/c1-37-a.ogg'
import heartsQAudioB from './ogg/c1-37-b.ogg'
import heartsQAudioC from './ogg/c1-37-c.ogg'
import heartsKAudioA from './ogg/c1-38-a.ogg'
import heartsKAudioB from './ogg/c1-38-b.ogg'
import heartsKAudioC from './ogg/c1-38-c.ogg'
import heartsAAudioA from './ogg/c1-39-a.ogg'
import heartsAAudioB from './ogg/c1-39-b.ogg'
import heartsAAudioC from './ogg/c1-39-c.ogg'
import clubs2AudioA from './ogg/c1-40-a.ogg'
import clubs2AudioB from './ogg/c1-40-b.ogg'
import clubs2AudioC from './ogg/c1-40-c.ogg'
import clubs3AudioA from './ogg/c1-41-a.ogg'
import clubs3AudioB from './ogg/c1-41-b.ogg'
import clubs3AudioC from './ogg/c1-41-c.ogg'
import clubs4AudioA from './ogg/c1-42-a.ogg'
import clubs4AudioB from './ogg/c1-42-b.ogg'
import clubs4AudioC from './ogg/c1-42-c.ogg'
import clubs5AudioA from './ogg/c1-43-a.ogg'
import clubs5AudioB from './ogg/c1-43-b.ogg'
import clubs5AudioC from './ogg/c1-43-c.ogg'
import clubs6AudioA from './ogg/c1-44-a.ogg'
import clubs6AudioB from './ogg/c1-44-b.ogg'
import clubs6AudioC from './ogg/c1-44-c.ogg'
import clubs7AudioA from './ogg/c1-45-a.ogg'
import clubs7AudioB from './ogg/c1-45-b.ogg'
import clubs7AudioC from './ogg/c1-45-c.ogg'
import clubs8AudioA from './ogg/c1-46-a.ogg'
import clubs8AudioB from './ogg/c1-46-b.ogg'
import clubs8AudioC from './ogg/c1-46-c.ogg'
import clubs9AudioA from './ogg/c1-47-a.ogg'
import clubs9AudioB from './ogg/c1-47-b.ogg'
import clubs9AudioC from './ogg/c1-47-c.ogg'
import clubs10AudioA from './ogg/c1-48-a.ogg'
import clubs10AudioB from './ogg/c1-48-b.ogg'
import clubs10AudioC from './ogg/c1-48-c.ogg'
import clubsJAudioA from './ogg/c1-49-a.ogg'
import clubsJAudioB from './ogg/c1-49-b.ogg'
import clubsJAudioC from './ogg/c1-49-c.ogg'
import clubsQAudioA from './ogg/c1-50-a.ogg'
import clubsQAudioB from './ogg/c1-50-b.ogg'
import clubsQAudioC from './ogg/c1-50-c.ogg'
import clubsKAudioA from './ogg/c1-51-a.ogg'
import clubsKAudioB from './ogg/c1-51-b.ogg'
import clubsKAudioC from './ogg/c1-51-c.ogg'
import clubsAAudioA from './ogg/c1-52-a.ogg'
import clubsAAudioB from './ogg/c1-52-b.ogg'
import clubsAAudioC from './ogg/c1-52-c.ogg'

export default [
  [
    [diamonds2AudioA, diamonds2AudioB, diamonds2AudioC],
    [diamonds3AudioA, diamonds3AudioB, diamonds3AudioC],
    [diamonds4AudioA, diamonds4AudioB, diamonds4AudioC],
    [diamonds5AudioA, diamonds5AudioB, diamonds5AudioC],
    [diamonds6AudioA, diamonds6AudioB, diamonds6AudioC],
    [diamonds7AudioA, diamonds7AudioB, diamonds7AudioC],
    [diamonds8AudioA, diamonds8AudioB, diamonds8AudioC],
    [diamonds9AudioA, diamonds9AudioB, diamonds9AudioC],
    [diamonds10AudioA, diamonds10AudioB, diamonds10AudioC],
    [diamondsJAudioA, diamondsJAudioB, diamondsJAudioC],
    [diamondsQAudioA, diamondsQAudioB, diamondsQAudioC],
    [diamondsKAudioA, diamondsKAudioB, diamondsKAudioC],
    [diamondsAAudioA, diamondsAAudioB, diamondsAAudioC],
  ],
  [
    [spades2AudioA, spades2AudioB, spades2AudioC],
    [spades3AudioA, spades3AudioB, spades3AudioC],
    [spades4AudioA, spades4AudioB, spades4AudioC],
    [spades5AudioA, spades5AudioB, spades5AudioC],
    [spades6AudioA, spades6AudioB, spades6AudioC],
    [spades7AudioA, spades7AudioB, spades7AudioC],
    [spades8AudioA, spades8AudioB, spades8AudioC],
    [spades9AudioA, spades9AudioB, spades9AudioC],
    [spades10AudioA, spades10AudioB, spades10AudioC],
    [spadesJAudioA, spadesJAudioB, spadesJAudioC],
    [spadesQAudioA, spadesQAudioB, spadesQAudioC],
    [spadesKAudioA, spadesKAudioB, spadesKAudioC],
    [spadesAAudioA, spadesAAudioB, spadesAAudioC],
  ],
  [
    [hearts2AudioA, hearts2AudioB, hearts2AudioC],
    [hearts3AudioA, hearts3AudioB, hearts3AudioC],
    [hearts4AudioA, hearts4AudioB, hearts4AudioC],
    [hearts5AudioA, hearts5AudioB, hearts5AudioC],
    [hearts6AudioA, hearts6AudioB, hearts6AudioC],
    [hearts7AudioA, hearts7AudioB, hearts7AudioC],
    [hearts8AudioA, hearts8AudioB, hearts8AudioC],
    [hearts9AudioA, hearts9AudioB, hearts9AudioC],
    [hearts10AudioA, hearts10AudioB, hearts10AudioC],
    [heartsJAudioA, heartsJAudioB, heartsJAudioC],
    [heartsQAudioA, heartsQAudioB, heartsQAudioC],
    [heartsKAudioA, heartsKAudioB, heartsKAudioC],
    [heartsAAudioA, heartsAAudioB, heartsAAudioC],
  ],
  [
    [clubs2AudioA, clubs2AudioB, clubs2AudioC],
    [clubs3AudioA, clubs3AudioB, clubs3AudioC],
    [clubs4AudioA, clubs4AudioB, clubs4AudioC],
    [clubs5AudioA, clubs5AudioB, clubs5AudioC],
    [clubs6AudioA, clubs6AudioB, clubs6AudioC],
    [clubs7AudioA, clubs7AudioB, clubs7AudioC],
    [clubs8AudioA, clubs8AudioB, clubs8AudioC],
    [clubs9AudioA, clubs9AudioB, clubs9AudioC],
    [clubs10AudioA, clubs10AudioB, clubs10AudioC],
    [clubsJAudioA, clubsJAudioB, clubsJAudioC],
    [clubsQAudioA, clubsQAudioB, clubsQAudioC],
    [clubsKAudioA, clubsKAudioB, clubsKAudioC],
    [clubsAAudioA, clubsAAudioB, clubsAAudioC],
  ]
]