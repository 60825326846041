import diamonds2ImgSm from './c1-01-320.jpg'
import diamonds2ImgLg from './c1-01-1000.jpg'
import diamonds3ImgSm from './c1-02-320.jpg'
import diamonds3ImgLg from './c1-02-1000.jpg'
import diamonds4ImgSm from './c1-03-320.jpg'
import diamonds4ImgLg from './c1-03-1000.jpg'
import diamonds5ImgSm from './c1-04-320.jpg'
import diamonds5ImgLg from './c1-04-1000.jpg'
import diamonds6ImgSm from './c1-05-320.jpg'
import diamonds6ImgLg from './c1-05-1000.jpg'
import diamonds7ImgSm from './c1-06-320.jpg'
import diamonds7ImgLg from './c1-06-1000.jpg'
import diamonds8ImgSm from './c1-07-320.jpg'
import diamonds8ImgLg from './c1-07-1000.jpg'
import diamonds9ImgSm from './c1-08-320.jpg'
import diamonds9ImgLg from './c1-08-1000.jpg'
import diamonds10ImgSm from './c1-09-320.jpg'
import diamonds10ImgLg from './c1-09-1000.jpg'
import diamondsJImgSm from './c1-10-320.jpg'
import diamondsJImgLg from './c1-10-1000.jpg'
import diamondsQImgSm from './c1-11-320.jpg'
import diamondsQImgLg from './c1-11-1000.jpg'
import diamondsKImgSm from './c1-12-320.jpg'
import diamondsKImgLg from './c1-12-1000.jpg'
import diamondsAImgSm from './c1-13-320.jpg'
import diamondsAImgLg from './c1-13-1000.jpg'
import spades2ImgSm from './c1-14-320.jpg'
import spades2ImgLg from './c1-14-1000.jpg'
import spades3ImgSm from './c1-15-320.jpg'
import spades3ImgLg from './c1-15-1000.jpg'
import spades4ImgSm from './c1-16-320.jpg'
import spades4ImgLg from './c1-16-1000.jpg'
import spades5ImgSm from './c1-17-320.jpg'
import spades5ImgLg from './c1-17-1000.jpg'
import spades6ImgSm from './c1-18-320.jpg'
import spades6ImgLg from './c1-18-1000.jpg'
import spades7ImgSm from './c1-19-320.jpg'
import spades7ImgLg from './c1-19-1000.jpg'
import spades8ImgSm from './c1-20-320.jpg'
import spades8ImgLg from './c1-20-1000.jpg'
import spades9ImgSm from './c1-21-320.jpg'
import spades9ImgLg from './c1-21-1000.jpg'
import spades10ImgSm from './c1-22-320.jpg'
import spades10ImgLg from './c1-22-1000.jpg'
import spadesJImgSm from './c1-23-320.jpg'
import spadesJImgLg from './c1-23-1000.jpg'
import spadesQImgSm from './c1-24-320.jpg'
import spadesQImgLg from './c1-24-1000.jpg'
import spadesKImgSm from './c1-25-320.jpg'
import spadesKImgLg from './c1-25-1000.jpg'
import spadesAImgSm from './c1-26-320.jpg'
import spadesAImgLg from './c1-26-1000.jpg'
import hearts2ImgSm from './c1-27-320.jpg'
import hearts2ImgLg from './c1-27-1000.jpg'
import hearts3ImgSm from './c1-28-320.jpg'
import hearts3ImgLg from './c1-28-1000.jpg'
import hearts4ImgSm from './c1-29-320.jpg'
import hearts4ImgLg from './c1-29-1000.jpg'
import hearts5ImgSm from './c1-30-320.jpg'
import hearts5ImgLg from './c1-30-1000.jpg'
import hearts6ImgSm from './c1-31-320.jpg'
import hearts6ImgLg from './c1-31-1000.jpg'
import hearts7ImgSm from './c1-32-320.jpg'
import hearts7ImgLg from './c1-32-1000.jpg'
import hearts8ImgSm from './c1-33-320.jpg'
import hearts8ImgLg from './c1-33-1000.jpg'
import hearts9ImgSm from './c1-34-320.jpg'
import hearts9ImgLg from './c1-34-1000.jpg'
import hearts10ImgSm from './c1-35-320.jpg'
import hearts10ImgLg from './c1-35-1000.jpg'
import heartsJImgSm from './c1-36-320.jpg'
import heartsJImgLg from './c1-36-1000.jpg'
import heartsQImgSm from './c1-37-320.jpg'
import heartsQImgLg from './c1-37-1000.jpg'
import heartsKImgSm from './c1-38-320.jpg'
import heartsKImgLg from './c1-38-1000.jpg'
import heartsAImgSm from './c1-39-320.jpg'
import heartsAImgLg from './c1-39-1000.jpg'
import clubs2ImgSm from './c1-40-320.jpg'
import clubs2ImgLg from './c1-40-1000.jpg'
import clubs3ImgSm from './c1-41-320.jpg'
import clubs3ImgLg from './c1-41-1000.jpg'
import clubs4ImgSm from './c1-42-320.jpg'
import clubs4ImgLg from './c1-42-1000.jpg'
import clubs5ImgSm from './c1-43-320.jpg'
import clubs5ImgLg from './c1-43-1000.jpg'
import clubs6ImgSm from './c1-44-320.jpg'
import clubs6ImgLg from './c1-44-1000.jpg'
import clubs7ImgSm from './c1-45-320.jpg'
import clubs7ImgLg from './c1-45-1000.jpg'
import clubs8ImgSm from './c1-46-320.jpg'
import clubs8ImgLg from './c1-46-1000.jpg'
import clubs9ImgSm from './c1-47-320.jpg'
import clubs9ImgLg from './c1-47-1000.jpg'
import clubs10ImgSm from './c1-48-320.jpg'
import clubs10ImgLg from './c1-48-1000.jpg'
import clubsJImgSm from './c1-49-320.jpg'
import clubsJImgLg from './c1-49-1000.jpg'
import clubsQImgSm from './c1-50-320.jpg'
import clubsQImgLg from './c1-50-1000.jpg'
import clubsKImgSm from './c1-51-320.jpg'
import clubsKImgLg from './c1-51-1000.jpg'
import clubsAImgSm from './c1-52-320.jpg'
import clubsAImgLg from './c1-52-1000.jpg'

export default [
  [
    [diamonds2ImgSm, diamonds2ImgLg],
    [diamonds3ImgSm, diamonds3ImgLg],
    [diamonds4ImgSm, diamonds4ImgLg],
    [diamonds5ImgSm, diamonds5ImgLg],
    [diamonds6ImgSm, diamonds6ImgLg],
    [diamonds7ImgSm, diamonds7ImgLg],
    [diamonds8ImgSm, diamonds8ImgLg],
    [diamonds9ImgSm, diamonds9ImgLg],
    [diamonds10ImgSm, diamonds10ImgLg],
    [diamondsJImgSm, diamondsJImgLg],
    [diamondsQImgSm, diamondsQImgLg],
    [diamondsKImgSm, diamondsKImgLg],
    [diamondsAImgSm, diamondsAImgLg],
  ],
  [
    [spades2ImgSm, spades2ImgLg],
    [spades3ImgSm, spades3ImgLg],
    [spades4ImgSm, spades4ImgLg],
    [spades5ImgSm, spades5ImgLg],
    [spades6ImgSm, spades6ImgLg],
    [spades7ImgSm, spades7ImgLg],
    [spades8ImgSm, spades8ImgLg],
    [spades9ImgSm, spades9ImgLg],
    [spades10ImgSm, spades10ImgLg],
    [spadesJImgSm, spadesJImgLg],
    [spadesQImgSm, spadesQImgLg],
    [spadesKImgSm, spadesKImgLg],
    [spadesAImgSm, spadesAImgLg],
  ],
  [
    [hearts2ImgSm, hearts2ImgLg],
    [hearts3ImgSm, hearts3ImgLg],
    [hearts4ImgSm, hearts4ImgLg],
    [hearts5ImgSm, hearts5ImgLg],
    [hearts6ImgSm, hearts6ImgLg],
    [hearts7ImgSm, hearts7ImgLg],
    [hearts8ImgSm, hearts8ImgLg],
    [hearts9ImgSm, hearts9ImgLg],
    [hearts10ImgSm, hearts10ImgLg],
    [heartsJImgSm, heartsJImgLg],
    [heartsQImgSm, heartsQImgLg],
    [heartsKImgSm, heartsKImgLg],
    [heartsAImgSm, heartsAImgLg],
  ],
  [
    [clubs2ImgSm, clubs2ImgLg],
    [clubs3ImgSm, clubs3ImgLg],
    [clubs4ImgSm, clubs4ImgLg],
    [clubs5ImgSm, clubs5ImgLg],
    [clubs6ImgSm, clubs6ImgLg],
    [clubs7ImgSm, clubs7ImgLg],
    [clubs8ImgSm, clubs8ImgLg],
    [clubs9ImgSm, clubs9ImgLg],
    [clubs10ImgSm, clubs10ImgLg],
    [clubsJImgSm, clubsJImgLg],
    [clubsQImgSm, clubsQImgLg],
    [clubsKImgSm, clubsKImgLg],
    [clubsAImgSm, clubsAImgLg],
  ]
]