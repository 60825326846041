import React from 'react';
import SongCard from './SongCard';

const ActiveHand = ({ activeHand, removeFromActiveHand, setCurrentTrackIndex }) => (
  <div className="flex-1 h-100 p-4 h-100">
    <div className="flex h-100 flex-col space-y-4 lg:justify-center lg:flex-row lg:space-x-4 lg:space-y-0">
      {activeHand.map((song, index) => (
        <div key={index} className="flex justify-center">
          <SongCard
            song={song}
            width="90"
            onClick={() => setCurrentTrackIndex(index)}
          />
          <span>
            <button
              className="p-1 bg-red-500 text-white rounded-full"
              onClick={() => removeFromActiveHand(index)}
            >
              x
            </button>
          </span>
        </div>
      ))}
    </div>
  </div>
);

export default ActiveHand;
