import React, { useEffect, useRef, useState } from 'react'
import Drawer from './components/Drawer'
import ActiveHand from './components/ActiveHand'
import AudioPlayer from './components/AudioPlayer'

function App() {
  const [activeHand, setActiveHand] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(undefined)
  const mainWindowRef = useRef(null)
  const [mainWindowHeight, setMainWindowHeight] = useState(0)

  const advanceTrack = () => {
    if (activeHand.length) {
      const newTrackIndex = (currentTrackIndex + 1) % activeHand.length
      handleSetCurrentTrackIndex(newTrackIndex)
    }
  }

  const handleSetCurrentTrackIndex = (index) => {
    console.log("new track index: ", index)
    setCurrentTrackIndex(index)
  }

  const handleAddToActiveHand = (song) => {
    if (activeHand.length < 8) {
      setActiveHand([...activeHand, song])
    }
  }

  const handleRemoveFromActiveHand = (index) => {
    const updatedHand = [...activeHand]
    updatedHand.splice(index, 1)
    setActiveHand(updatedHand)
  }

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  useEffect(() => {

  }, mainWindowRef?.current?.height)

  return (
    <div className="h-screen flex flex-col troub-background">

      <div className="p-4 troubaheader flex flex-col sm:flex-row justify-between">
        <div className="flex flex-row justify-center">
          <a href="https://www.troubadeck.com"><img src="logo192.png" className="max-w-10" /></a>
          <a href="https://www.troubadeck.com"><h1 class="mb-4 text-2xl leading-none tracking-tight sm:text-3xl md:text-4xl">The Troubadeck (alpha)</h1></a>
        </div>
        <div className="align-middle flex flex-row justify-center">
          <a href="https://www.troubadeck.com/"><span className="p-2">Home</span></a>
          <a href="https://www.troubadeck.com/about/"><span className="p-2">About</span></a>
          <a href="https://www.troubadeck.com/games/"><span className="p-2">Games</span></a>
          <a href="https://www.troubadeck.com/contact/"><span className="p-2">Contact</span></a>
        </div>
      </div>
      <div className="h-full max-h-fit flex flex-col">
        <Drawer
          isOpen={isDrawerOpen}
          toggleDrawer={handleToggleDrawer}
          addToActiveHand={handleAddToActiveHand} />

        <div ref={mainWindowRef} >
          <ActiveHand
            activeHand={activeHand}
            currentTrackIndex={currentTrackIndex}
            removeFromActiveHand={handleRemoveFromActiveHand}
            setCurrentTrackIndex={handleSetCurrentTrackIndex} />
        </div>
      </div>
      <AudioPlayer
        activeHand={activeHand}
        currentTrackIndex={currentTrackIndex}
        isDrawerOpen={isDrawerOpen}
        setCurrentTrackIndex={handleSetCurrentTrackIndex}
        toggleDrawer={handleToggleDrawer} />
    </div>
  )
}

export default App;